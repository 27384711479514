<template>
  <div class="product-images">
    <v-row no-gutters>
      <v-col cols="12" md="12" class="d-flex justify-center">
        <div class="image-block-wrapper">
          <swiper :options="swiperOptionTop" ref="swiperTop">
            <swiper-slide v-for="media in images" :key="media.medium">
              <img
                class="image-mobile"
                v-if="$vuetify.breakpoint.xs"
                :key="'img_' + media.medium"
                :src="media.medium"
                :alt="'Anteprima immagine prodotto ' + name"
                @click.stop="dialog = true"
              />
              <zoom-on-hover
                v-else
                :scale="1.5"
                :alt="'Anteprima immagine prodotto ' + name"
                :img-normal="selectedImage.medium"
                :img-zoom="selectedImage.large"
              ></zoom-on-hover>
            </swiper-slide>
          </swiper>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="12"
        class="d-flex justify-center thumb-wrapper"
        v-if="images.length > 1"
      >
        <swiper :options="swiperOptionThumbs" ref="swiperThumbs">
          <swiper-slide v-for="media in images" :key="media.medium">
            <img
              :key="'img_' + media.medium"
              :src="media.small"
              :alt="'Anteprima immagine prodotto ' + name"
              :class="{ selected: selectedImage == media }"
              @click="selectedImage = media"
            />
          </swiper-slide>
          <div class="swiper-button-next" slot="button-next"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
        </swiper>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="zoom-dialog"
    >
      <div class="zoom-dialog-content">
        <v-btn @click.stop="dialog = false" dense elevation="0">Chiudi</v-btn>
        <pinch-zoom height="100%">
          <img
            :alt="'Anteprima immagine prodotto ' + name"
            :src="selectedImage.large"
          />
        </pinch-zoom>
      </div>
    </v-dialog>
  </div>
</template>
<style scope="global" lang="scss">
.image-preview-wrapper {
  border-radius: 15px;
  border: 1px solid #f4f4f4;
  .image-block-wrapper {
    width: 100%;
    text-align: center;
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      width: 400px;
    }
    .swiper-slide .image-mobile {
      width: 100%;
      height: auto;
    }
  }
  .thumb-wrapper {
    .swiper-container {
      position: unset;
      .swiper-button {
        &-next {
          right: 5px;
        }
        &-right {
          left: 5px;
        }
      }
    }
  }
  .big-preview {
    margin: 25px;
    width: 100%;
    height: 400px;
    max-height: 400px;
  }
  .swiper-slide {
    width: initial;
  }
  .thumb-wrapper {
    margin: 15px 0;
    padding-left: 40px;
    padding-right: 40px;
    position: relative;
    .thumb-preview {
      width: 100px;
    }
    img {
      padding: 5px;
      width: 80px;
      height: 80px;
      border: 1px solid transparent;
      border-radius: 4px;
      &.selected {
        border-color: $accent;
      }
    }
  }
}
.swiper-button-disabled {
  opacity: 0 !important;
  transition: opacity 0.2s ease-in-out;
}
.swiper-button-prev,
.swiper-button-next {
  z-index: 4;
}
.zoom-dialog {
  background-color: black;
}
.zoom-dialog-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  .pinch-zoom-wrapper {
    flex-grow: 1;
  }
}
</style>
<script>
import PinchZoom from "vue-pinch-zoom";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "ProductImages",
  props: {
    images: { type: Array, required: true },
    name: { type: String, required: false }
  },
  components: { PinchZoom, Swiper, SwiperSlide },
  data() {
    return {
      selectedImage: {},
      dialog: false,
      swiperOptionThumbs: {
        slidesPerView: "auto",
        slidesPerGroup: 1,
        spaceBetween: 10,
        watchOverflow: true,
        centeredSlides: this.$vuetify.breakpoint.xsOnly,
        slideToClickedSlide: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
      swiperOptionTop: {
        allowTouchMove: this.$vuetify.breakpoint.xsOnly,
        spaceBetween: 10,
        slidesPerView: 1
      }
    };
  },
  methods: {
    openZoom() {},
    selectImageOnSlide() {
      let activeIndex = this.$refs.swiperThumbs.$swiper.activeIndex;
      this.selectedImage = this.images[activeIndex];
    }
  },
  mounted() {
    this.selectedImage = this.images[0];
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.$swiper;
      const swiperThumbs = this.$refs.swiperThumbs?.$swiper;
      swiperTop.controller.control = swiperThumbs;
      if (swiperThumbs) {
        swiperThumbs.controller.control = swiperTop;
        swiperThumbs.on("activeIndexChange", this.selectImageOnSlide);
      }
    });
  }
};
</script>
