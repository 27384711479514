<template>
  <div>
    <!-- <v-container
      v-if="
        category.categoryId === product.categoryId &&
          getProposals('header-banner').length > 0
      "
    >
      <ProposalSlider
        :proposals="getProposals('header-banner')"
        :key="'header-banner_' + key"
      />
    </v-container>-->

    <Breadcrumb :items="breadcrumb" />

    <v-container v-if="product" class="product-detail">
      <ProductDetail
        v-if="product.productId"
        :product="product"
        :selectedImage="selectedImage"
        :key="'product_detail_' + key"
        @selectImage="selectImage"
      />
      <ProductDescription
        v-if="product.productId"
        :product="product"
        :key="'product_description_' + key"
        :productMetadata="product.metaData"
        :ingredientList="product.ingredientList"
      />
    </v-container>
    <v-container>
      <ProductListSlider
        :layout="220"
        :limit="12"
        v-if="product.productId"
        :productId="product.productId"
        :key="product.productId + '_1'"
        title="Ti potrebbero interessare anche:"
        :paginationClass="'swiper-pagination-interesse'"
      />
      <ProductListSlider
        :layout="223"
        :limit="12"
        v-if="product.productId"
        :productId="product.productId"
        :key="product.productId + '_2'"
        title="Suggeriti per te:"
        :paginationClass="'swiper-pagination-suggeriti'"
      />
    </v-container>
    <v-container v-if="showNoProductCard">
      <v-card
        class="d-flex align-center mb-6 justify-center"
        flat
        height="100"
        tile
      >
        <v-card class="pa-2" flat>
          <h3 class="font-weight-regular login-sub">
            {{ $t("products.noDetailProduct") }}
          </h3>
        </v-card>
      </v-card>
    </v-container>
    <v-container
      v-if="
        category.categoryId === product.categoryId &&
          getProposals('footer-banner').length > 0
      "
    >
      <ProposalSlider
        :proposals="getProposals('footer-banner')"
        :key="'footer-banner_' + key"
      />
    </v-container>
  </div>
</template>

<script>
import ProductService from "~/service/productService";
import ProductDescription from "@/components/product/ProductDescription";
import ProductDetail from "@/components/product/ProductDetail";
// import ProductImages from "@/components/product/ProductImages";
import ProductListSlider from "@/components/product/ProductListSlider";
import categoryMixin from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";
import ProposalSlider from "@/components/proposal/ProposalSlider";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import get from "lodash/get";
import join from "lodash/join";
import compact from "lodash/compact";
import analyticsService from "~/service/analyticsService";

export default {
  name: "Product",
  mixins: [categoryMixin, deliveryReactive],
  jsonld() {
    if (this.product.productId) {
      let images = this.product.media.map(item => item.medium);
      return {
        "@context": "https://schema.org/",
        "@type": "Product",
        name: this.product.name,
        image: images,
        description: this.product.description,
        sku: this.product.codInt,
        mpn: this.product.codInt,
        brand: {
          "@type": "Brand",
          name: this.product.shortDescr
        },
        // review: {
        //   "@type": "Review",
        //   reviewRating: {
        //     "@type": "Rating",
        //     ratingValue: "4",
        //     bestRating: "5"
        //   },
        //   author: {
        //     "@type": "Organization",
        //     name: "Iperal"
        //   }
        // },
        // aggregateRating: {
        //   "@type": "AggregateRating",
        //   ratingValue: "4.4",
        //   reviewCount: "89"
        // },
        offers: {
          "@type": "Offer",
          url: process.env.VUE_APP_EBSN_URL + "/" + this.product.slug,
          priceCurrency: "EUR",
          price: this.product.price ? this.product.price : 0,
          priceValidUntil: "2050-12-31", // NON HO UNA DATA VALIDA DI PREZZO
          itemCondition: "https://schema.org/NewCondition",
          availability:
            this.product.available >= 0
              ? "https://schema.org/InStock"
              : "https://schema.org/OutOfStock"
        }
      };
    }
  },
  data() {
    return {
      product: {
        productId: null,
        name: "",
        shortDescr: "",
        description: ""
      },
      showNoProductCard: false,
      selectedImage: null,
      key: 1
    };
  },
  components: {
    ProductDescription,
    ProductDetail,
    ProductListSlider,
    ProposalSlider,
    Breadcrumb
  },
  computed: {
    getFullName() {
      return join(
        compact([
          this.product.name,
          this.product.shortDescr,
          this.product.description
        ]),
        " "
      );
    }
  },
  methods: {
    async getProduct() {
      var data = await ProductService.getProductBySlug(this.slug);
      if (data) {
        this.product = data;
        if (this.product.categoryId) {
          this.setCategory(this.product.categoryId);
        }

        if (this.product && this.product.media && this.product.media.length) {
          this.selectImage(this.product.media[0]);
        }
      } else {
        this.showNoProductCard = true;
      }
    },
    async setCategory(categoryId) {
      this.$store.dispatch("category/setCategory", {
        slug: categoryId,
        reset: false
      });
    },
    async selectImage(image) {
      this.selectedImage = image;
      this.selectedImage.thumb = image.medium;
    },
    async reload() {
      await this.getProduct();
      this.key += 1;
    }
  },
  async mounted() {
    this.slug = this.$route.params.slug;
    this.showNoProductCard = false;
    await this.getProduct();
    analyticsService.viewProductDetail(this.product);
  },
  watch: {
    async $route(to) {
      if (to.params.slug != this.slug) {
        this.slug = to.params.slug;
        await this.getProduct();
        analyticsService.viewProductDetail(this.product);
      }
    }
  },
  metaInfo() {
    return {
      title: get(
        this.product,
        "metaData.product_seo.SEO_TITLE",
        this.getFullName
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(
            this.product,
            "metaData.product_seo.SEO_DESCRIPTION",
            this.getFullName +
              " online. " +
              this.category.name +
              " in offerta e promozione su Iperal Spesa Online con consegna a casa, ritira in negozio e ritiro locker."
          )
        }
      ]
    };
  }
};
</script>
