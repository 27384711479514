<template>
  <swiper :options="swiperOption" ref="swiperRef">
    <swiper-slide
      v-for="proposal in proposals"
      :key="proposal.id"
      :ref="swiperRef"
    >
      <ProposalImage :proposal="proposal" />
    </swiper-slide>
    <div
      v-if="proposals.length > 0"
      class="swiper-pagination"
      :class="paginationClass"
      slot="pagination"
    ></div>
  </swiper>
</template>
<script>
import ProposalImage from "./ProposalImage";

import deliveryReactive from "~/mixins/deliveryReactive";
export default {
  name: "ProposalSlider",
  props: {
    proposals: { type: Array, required: true },
    paginationClass: { type: String, default: "swiper-pagination-banner" }
  },
  components: { ProposalImage },
  mixins: [deliveryReactive],
  data() {
    return {
      swiperRef: null,
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 20,
        autoplay: true,
        watchOverflow: true,
        pagination: {
          el: "." + this.paginationClass,
          clickable: true
        }
      }
    };
  },
  methods: {
    reload() {
      if (this.$refs.swiperRef.swiperInstance) {
        this.$refs.swiperRef.swiperInstance.slideTo(0);
        this.$refs.swiperRef.swiperInstance.autoplay.start();
      }
    }
  }
};
</script>
