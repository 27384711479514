<template>
  <v-row v-if="product">
    <v-col md="6" cols="12" order="2" order-md="1" class="py-0 py-sm-3">
      <v-card flat v-if="selectedImage" class="image-preview-wrapper">
        <ProductImages
          :images="product.media"
          :name="product.name"
          :key="product.productId"
        />
      </v-card>
    </v-col>

    <v-col md="6" cols="12" order="1" order-md="2" class="py-0 py-sm-3">
      <div class="d-flex align-md-start">
        <ProductPromo
          :warehousePromo="product.warehousePromo"
          v-if="product.warehousePromo && !$vuetify.breakpoint.smAndDown"
        />
        <ProductAcqLimit :product="product" class="mr-1" />
        <v-tooltip
          bottom
          v-if="
            product.warehousePromo &&
              product.warehousePromo.view.bubble &&
              !$vuetify.breakpoint.smAndDown
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-2" v-bind="attrs" v-on="on" @click.stop.prevent=""
              >mdi-information-outline</v-icon
            >
          </template>
          <span
            class="tooltip-content-bubble"
            v-html="product.warehousePromo.view.bubble"
          ></span>
        </v-tooltip>
      </div>

      <div class="product-brand">
        {{ product.shortDescr }}
      </div>
      <div itemprop="name" class="product-name">{{ product.name }}</div>
      <div
        class="product-descr"
        v-if="
          product.metaData &&
            product.metaData.product_info &&
            product.metaData.product_info.weight_description
        "
      >
        {{ product.metaData.product_info.weight_description }}
      </div>
      <div class="product-descr">
        <span
          class="weight-unit"
          v-if="
            product.productInfos &&
              product.productInfos.TIPOLOGIA == 'Pezzo' &&
              product.priceUmDisplay
          "
        >
          {{ $n(product.priceUmDisplay, "currency") }}/{{
            product.weightUnitDisplay
          }}
          -
        </span>
        {{ product.description }}
      </div>
      <div class="product-classes-wrapper">
        <ProductInfo v-if="product.productInfos.REFUND_ASL == '1'" />

        <ProductClass
          :pClass="pClass"
          v-for="pClass in productClasses"
          :key="pClass.productClassId"
        />
      </div>
      <v-divider></v-divider>

      <div class="d-flex align-center justify-space-between price-qty-block">
        <ProductPrice :product="product" :showStandard="false"></ProductPrice>
        <div
          class="qty-wrap rounded-pill"
          v-if="
            product.available > 0 &&
              !(
                product.productInfos &&
                product.productInfos.USER_DISABLED == '1'
              )
          "
          :class="{ 'not-empty': quantity > 0 }"
        >
          <a
            role="button"
            class="minus rounded-circle"
            v-on:click.prevent.stop="minus"
            v-ripple
          >
            <v-icon aria-label="Aggiungere meno quantità">$minus</v-icon>
          </a>
          <div class="val-cnt">
            <span class="val">{{ quantity }} {{ unit }}</span>
            <span class="small">{{ quantityPerUnit }}</span>
          </div>
          <a
            aria-label="Aggiungi al tuo carrello"
            role="button"
            class="plus rounded-circle"
            v-on:click.prevent="plus"
            v-ripple
          >
            <v-icon v-if="quantity > 0" aria-label="Aggiungere più quantità"
              >$plus</v-icon
            >
            <v-icon v-else aria-label="Aggiungi al tuo carrello">$cart</v-icon>
          </a>
        </div>
        <div v-else class="not-available">
          Il prodotto non è disponibile
        </div>
      </div>

      <div class="product-lock-container">
        <v-row no-gutters class="mx-1">
          <ProductDayLock
            class="ma-1"
            v-if="product.productInfos.DAY_LOCK"
            :days="product.productInfos.DAY_LOCK"
            :showTooltip="false"
            :isCheckout="false"
          />
          <ProductLockCutoff
            class="ma-1"
            v-if="product.productInfos.PRODUCT_LOCK_CUTOFF"
            :hours="product.productInfos.PRODUCT_LOCK_CUTOFF"
            :showTooltip="false"
            :isCheckout="false"
          />
          <ProductAxBCutoff
            class="ma-1"
            v-if="product.productInfos.PRODUCT_AXB_CUTOFF"
            :hour="product.productInfos.PRODUCT_AXB_CUTOFF"
            :showTooltip="false"
            :isCheckout="false"
          />
          <ProductLeadTime
            class="ma-1"
            v-if="product.productInfos.LEAD_TIME"
            :time="product.productInfos.LEAD_TIME"
            :showTooltip="false"
            :isCheckout="false"
          />
        </v-row>
      </div>

      <v-divider></v-divider>

      <div
        class="d-flex align-center my-3 add-to-list justify-space-between justify-md-start"
      >
        <div class="d-flex align-center" @click="addToFavorites">
          <v-btn icon aria-label="Aggiungi ad una lista">
            <v-icon v-if="highlight" color="primary">$heartFull</v-icon>
            <v-icon v-else>$heart</v-icon>
          </v-btn>

          <span class="text-list">Aggiungi ad una lista</span>
        </div>
        <div class="d-flex">
          <ProductPromo
            :warehousePromo="product.warehousePromo"
            v-if="product.warehousePromo && $vuetify.breakpoint.smAndDown"
          />
          <v-tooltip
            bottom
            v-if="
              product.warehousePromo &&
                product.warehousePromo.view.bubble &&
                $vuetify.breakpoint.smAndDown
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="ml-2 tooltip-bubble-icon"
                v-bind="attrs"
                v-on="on"
                @click.stop.prevent=""
                >mdi-information-outline</v-icon
              >
            </template>
            <span
              class="tooltip-content-bubble"
              v-html="product.warehousePromo.view.bubble"
            ></span>
          </v-tooltip>
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<style scoped lang="scss">
.product-lock-container {
  .row {
    margin-left: 0px !important;
  }
  .day-lock,
  .hour-lock {
    cursor: pointer;
    margin: 5px;
    padding: 5px;
    border: 2px solid $primary;
    text-align: center;
    color: $primary;
    text-decoration: line-through;
  }
  .not-available-product {
    margin-bottom: 10px !important;
    margin-left: 0px !important;
  }
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPrice";
import ProductPromo from "@/components/product/ProductPromo";
import ProductInfo from "@/components/product/ProductInfo";
import ProductClass from "@/components/product/ProductClass";
import ProductImages from "@/components/product/ProductImages";
import ProductLeadTime from "@/components/product/ProductLeadTime";
import ProductDayLock from "@/components/product/ProductDayLock";
import ProductLockCutoff from "@/components/product/ProductLockCutoff";
import ProductAxBCutoff from "@/components/product/ProductAxBCutoff";
import ProductAcqLimit from "./ProductAcqLimit";

import productMixin from "~/mixins/product";
import { mapState, mapGetters } from "vuex";
export default {
  name: "ProductDetail",
  props: ["selectedImage", "product"],
  components: {
    ProductPrice,
    ProductPromo,
    ProductInfo,
    ProductClass,
    ProductImages,
    ProductLeadTime,
    ProductDayLock,
    ProductLockCutoff,
    ProductAxBCutoff,
    ProductAcqLimit
  },
  mixins: [productMixin],
  data() {
    return {
      imageDetailDialog: false
    };
  },
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    item() {
      return this.getItemByProductId(this.product.productId);
    },
    productClasses() {
      return this.product &&
        this.product.productClasses &&
        this.product.productClasses.length
        ? this.product.productClasses.filter(p => p.productClassId !== 10078)
        : [];
    }
  },
  methods: {
    selectImage(media) {
      this.$emit("selectImage", media);
    },
    openImageDialog() {
      this.imageDetailDialog = true;
    }
  }
};
</script>
