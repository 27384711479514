<template>
  <v-container class="product-description" v-if="tabs.length > 0">
    <v-tabs v-model="selectedTab">
      <v-tab v-for="tab in tabs" :key="tab.title" :class="tab.cssClass">
        {{ tab.title }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedTab">
      <v-tab-item v-for="tab in tabs" :key="tab.title" :class="tab.cssClass">
        <div
          v-for="item in tab.items"
          :key="item.title"
          class="pb-4"
          :class="item.cssClass"
        >
          <h4 v-if="item.title">{{ item.title }}</h4>
          <div
            v-for="path in item.paths"
            :key="path.key"
            v-html="path.content"
          />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<style lang="scss"></style>

<script>
import get from "lodash/get";
import kebabCase from "lodash/kebabCase";
export default {
  name: "ProductDescription",
  data() {
    return {
      selectedTab: null,
      productDescriptionTabs: [
        {
          title: "Caratteristiche",
          cssClass: "caratteristiche",
          items: [
            {
              cssClass: "sales",
              paths: ["metaData.product_description.sale_description"]
            },
            {
              // title: "Caratteristiche di prodotto",
              cssClass: "products",
              paths: [
                "metaData.product_info.CHARACTERISTICS",
                "metaData.product_description.features"
              ]
            },
            {
              title: "Certificazioni",
              cssClass: "certifications",
              paths: ["metaData.product_description.certifications"]
            },
            {
              title: "Consigli d'uso",
              cssClass: "use-advices",
              paths: ["metaData.product_description.use_advices"]
            },
            {
              title: "Conservazione",
              paths: ["metaData.product_description.storage"]
            },
            {
              title: "Smaltimento",
              paths: [
                "metaData.product_description.recycling",
                "metaData.product_description.recycling_other"
              ]
            },
            {
              title: "Tracciabilità",
              cssClass: "trace",
              paths: ["productInfos.TRACCIABILITA"]
            }
          ]
        },
        {
          title: "Ingredienti",
          cssClass: "ingredients",
          items: [
            {
              // title: "Ingredienti",
              cssClass: "ingredients",
              paths: [
                "metaData.product_info.PREPARATION_MODE",
                "metaData.product_description.ingredients"
              ]
            },

            {
              // title: "Allergeni",
              cssClass: "allergens",
              paths: [
                "metaData.product_info.ALLERGOLOGIC_INFORMATIONS",
                "metaData.product_description.allergens",
                "metaData.product_description.allergens_text",
                "metaData.product_description.allergens_other"
              ]
            }
          ]
        },
        {
          title: "Valori nutrizionali",
          items: [
            {
              paths: [
                "metaData.product_info.NUTRITIONAL_INFORMATIONS",
                "metaData.product_description.nutritional_values"
              ]
            }
          ]
        }
      ]
    };
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    productMetadata: {
      type: Object
    },
    ingredientList: {
      type: Object
    }
  },
  computed: {
    tabs() {
      let _this = this;
      let tabs = [];
      _this.productDescriptionTabs.forEach(tabMeta => {
        let items = [];
        tabMeta.items.forEach(parMeta => {
          let paths = [];
          parMeta.paths.forEach(pathMeta => {
            let content = get(_this.product, pathMeta);
            if (content) {
              paths.push({ key: pathMeta, content: content });
            }
          });
          if (paths.length > 0) {
            items.push({
              title: parMeta.title,
              paths: paths,
              cssClass: get(parMeta, "cssClass", kebabCase(parMeta.title))
            });
          }
        });
        if (items.length > 0) {
          tabs.push({
            title: tabMeta.title,
            items: items,
            cssClass: get(tabMeta, "cssClass", kebabCase(tabMeta.title))
          });
        }
      });

      return tabs;
    }
  }
};
</script>
